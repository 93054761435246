import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter,}from 'react-router-dom';
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import  Nomatch from './Nomatch';
// import {db}from '../src/firebase/config';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route  path="/"  element={<App/>} />
      <Route  path="web/app.html" element={
     
      < Nomatch/>
    
    }
    />
      
    </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


