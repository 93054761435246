import React from 'react';
import {Link} from 'react-router-dom';
// import './App.css';
function App() {
  return (
    
    <div className='w-screen h-screen'>
     
      <a href='build/web/app.html' className="text-3xl text-blue-500 font-bold text-center">
      Hello world!

    </a>
    <div><Link to='web/app.html' >flutter</Link></div>
    </div>
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );


  }

  
export default App;

