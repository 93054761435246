import React, { useEffect, useState } from 'react';

// if (window.location.pathname === "/build/web/app.html") {
//     setTimeout(() => {
//       window.location.reload();
//       console.log('nyes');

//     }, 3 * 1000);
//   }else{console.log('no');

// }

 const Nomatch=()=>{
   

    const [count,setCount]=useState(1);
    useEffect(()=>{

    },[]
    );
    if (window.location.pathname === "/web/app.html") {
        // setTimeout(() => {
          console.log('nyes');

          window.location.reload();
    
        // }, 1 * 1000);
      }else{console.log('no');
    
    }
    console.log(' Nomatch');

    return (<div className='flex justify-center'>
      <h2>読み込み中</h2>
      <div className='animate-spin h-10 w-10 border-4 border-blue-500 rounded-full border-t-transparent'></div>
      </div>);
    function aa(){

        console.log('i');
    }
  }
  export default Nomatch;